
import { getAction,getByIdAction,postAction,putAction,getByTypeAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunAttendance'
//调用 import { addAttend } from "@/api/work/attendance/attendance";

//新增
const addAttend = (params)=>postAction(baseURL+'/add',params);

const getInfoApi=(params)=>getByIdAction(baseURL+'/getInfo',params);

const edit=(params)=>putAction(baseURL+'/edit',params);

const delectAttendById=(params)=>deleteByIdAction(baseURL+'/delectById',params)

const listApi=(params)=>getAction(baseURL+'/list',params);


const getTotalVacationApi=(params)=>getAction(baseURL+'/getTotalVacation',params);
export{
    addAttend,
    getInfoApi,
    edit,
    delectAttendById,
    listApi,
    getTotalVacationApi
}
