<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <span class="dialog-footer">
      <el-button type="primary" @click="addAttendButton()">确 定</el-button>
      <el-button @click="handleClose()">取 消</el-button>
    </span>
    <el-form :model="addFrom" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="记录月份" prop="recordMonth">
            <el-date-picker
              v-model="addFrom.recordMonth"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择月"
              :disabled="isEditShow"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属公司" width="120px" prop="companyId">
            <el-select
              @change="AddSelectDept"
              v-model="companyFrom"
              filterable
              placeholder="请选择"
              style="width:70%"
              disabled
            >
              <el-option
                v-for="item in companyIdList"
                :key="item.uniqueId"
                :label="item.companyName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="加班补贴" prop="extraMoney" width="70px">
            <el-input v-model="addFrom.extraMoney" width="20px" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <!--  <el-row :gutter="24">
         <el-col :span="6">
          <el-form-item label="加班天数" width="150px">
            <el-input-number prop="overtimeHour" v-model="addFrom.overtimeHour" size="mini" />小时
          </el-form-item>
      </el-col>-->
      <!-- <el-col :span="8">
          <el-form-item label="调休天数" width="120px">
            <el-input-number prop="overtimeHour" v-model="addFrom.restDay" size="mini" />小时
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="请假天数" width="120px">
            <el-input-number prop="leaveDay" v-model="addFrom.leaveDay" size="mini" />小时
          </el-form-item>
        </el-col> 
       
      </el-row>-->

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="实际上班天数" width="120px">
            <el-input-number prop="workDay" v-model="addFrom.workDay" size="mini" />天
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="考勤备注" prop="attendanceRemark">
            <el-input v-model="addFrom.attendanceRemark" width="20px" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="应报销金额" prop="applyMoney">
            <el-input-number v-model="addFrom.applyMoney" width="20px" />
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="isApply">
          <el-form-item label="报销备注" prop="applyRemark" width="70px">
            <el-input v-model="addFrom.applyRemark" width="20px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="24">
        <el-col :span="24">
          <el-checkbox
            v-model="isApply"
            label="是否需要报销"
            border
            size="medium"
            style="margin:10px 30px  40px 30px"
            @change="isApplyButton()"
            :disabled="isEditShow"
          ></el-checkbox>
          <el-checkbox
            v-model="isTravel"
            label="是否出差"
            border
            size="medium"
            @change="isTravelButton"
            :disabled="isEditShow"
            style="margin:10px 30px  40px 30px"
          ></el-checkbox>
        </el-col>
      </el-row>-->
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="报销开始时间" prop="applyBeginData" width="70px">
            <el-date-picker
              v-model="addFrom.applyBeginData"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报销结束时间" prop="applyEndData" width="70px">
            <el-date-picker
              v-model="addFrom.applyEndData"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报销状态" prop="applyStatus" width="70px">
            <el-select v-model="addFrom.applyStatus" placeholder="请选择" @change="getApplyStatus">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <div v-if="isTravel">
        <el-button @click="addTravelButton">新增</el-button>
        <span>总共出差：{{this.tripSum}} 天</span>
        <el-table :data="travelFrom" border stripe style="width: 100%">
          <el-table-column label="序号" align="center" width="65">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="出差开始时间" prop="tripBeginData">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.tripBeginData"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >{{scope.row.tripBeginData}}</el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="出差结束时间" prop="tripEndData">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.tripEndData"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                @change="getEndData(scope.row)"
              >{{scope.row.tripEndData}}</el-date-picker>
            </template>
          </el-table-column>

          <el-table-column label="出差备注" prop="tripRemark">
            <template slot-scope="scope">
              <el-input v-model="scope.row.tripRemark" width="20px" />
            </template>
          </el-table-column>
          <el-table-column label="本月第几次出差" prop="tripFrequency">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.tripFrequency" width="20px" />
            </template>
          </el-table-column>

          <el-table-column label="出差方式" prop="tripTypeData">
            <template slot-scope="scope">
              <el-select v-model="scope.row.tripTypeData" filterable placeholder="请选择" @change="tripTypeButton(scope.row)">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.subbranch"
                  :value="item"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="操作" prop="cancelMoney">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="deleteRow(scope.$index, travelFrom)"
                type="text"
                size="small"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>-->
    </el-form>
  </el-dialog>
</template>

<script>
import { addAttend, getInfoApi, edit } from "@/api/work/attendance/attendance";
import { listcompanyApi } from "@/api/my/company/company";
export default {
  name: "attendanceAdd",
  data() {
    return {
      isApply: false,
      isTravel: false,
      isEditShow: false,
      dialogVisible: false,
      travelFrom: [],
      radio: null,
      attType: null,
      attendFrom: {
        addAttendceBillVO: {},
        addTripList: []
      },
      addFrom: {
        isApply: null,
        isTravel: null
      },
      tripSum: 0,
      options: [
        {
          id: 1,
          subbranch: "飞机"
        }
      ],
      tripTypeOptions: [],
      ids: null,
      title: null,
      companyIdList: [],
      companyFrom: {}
    };
  },
  methods: {
    show(id, type) {
      this.listcompanyButton();
      this.dialogVisible = true;
      this.isEditShow = false;
      if (type === 2) {
        this.isEditShow = true;
        this.getAttendInfoById(id);
        this.ids = id;
      }
      this.attType = type;
    },
    //确定
    addAttendButton() {
      if (this.addFrom.companyId == null || this.addFrom.companyId == undefined)
        return this.$message.error("请选择公司");
      if (
        this.addFrom.recordMonth == null ||
        this.addFrom.recordMonth == undefined
      )
        return this.$message.error("请选择月份");
      this.addFrom.isExtra = this.isApply === false ? 2 : 1;
      this.addFrom.isTrip = this.isTravel === false ? 2 : 1;
      this.addFrom.tripDay = this.tripSum;
      this.attendFrom.addAttendceBillVO = this.addFrom;
      this.attendFrom.addTripList = this.travelFrom;
      if (this.attType === 2) {
        this.addFrom.id = this.ids;
        edit({ addAttendceBillVO: this.addFrom }).then(res => {
          this.addFrom = {};
          this.dialogVisible = false;
          this.$parent.getAttendanceList();
        });
      } else {
        addAttend(this.attendFrom).then(res => {
          this.addFrom = {};
          this.dialogVisible = false;
          this.$parent.getAttendanceList();
        });
      }
    },
    //报销状态
    getApplyStatus() {},
    //关闭
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
    },
    //查询详情
    getAttendInfoById(id) {
      getInfoApi(id).then(res => {
        this.addFrom = res.result;
        if (res.result.isExtra === 1) {
          this.isApply = true;
        }
        if (res.result.isTrip === 1) {
          this.isTravel = true;
        }
      });
    },
    isApplyButton() {},
    isTravelButton() {},
    getTripType() {},
    addTravelButton() {
      var newTravle = {
        tripType: null
      };
      this.travelFrom.push(newTravle);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    getEndData(rows) {
      var sum = this.DateDiff(rows.tripBeginData, rows.tripEndData);
      this.tripSum = this.tripSum + sum;
    },
    DateDiff(sDate1, sDate2) {
      //sDate1和sDate2是2006-12-18格式
      var aDate, oDate1, oDate2, iDays;
      aDate = sDate1.split("-");
      oDate1 = new Date(aDate[0], aDate[1], aDate[2]); //转换为12-18-2006格式
      aDate = sDate2.split("-");
      oDate2 = new Date(aDate[0], aDate[1], aDate[2]);
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) + 1; //把相差的毫秒数转换为天数
      return iDays;
    },
    AddSelectDept(val) {
      this.addFrom.companyId = val.uniqueId;
      this.addFrom.companyName = val.companyName;
      this.addFrom.bankInfoId = val.bankId;
    },
    listcompanyButton() {
      listcompanyApi({companyStatus:2}).then(res => {
        this.companyIdList = res.result.records;
        console.log(res.result.records[0]);
        if (res.result.records.length !== 0) {
          this.addFrom.companyId = res.result.records[0].uniqueId;
          this.addFrom.companyName = res.result.records[0].companyName;
          this.addFrom.bankInfoId = res.result.records[0].bankId;
        }
      });
    },
    tripTypeButton(row) {
      row.tripType = row.tripTypeData.id;
    }
  }
};
</script>

<style lang="less" scoped>
</style> 