<template>
  <div>
    <el-dialog
      title="调整状态"
      :visible.sync="adjustDialogVisible"
      width="70%"
      @close="adjustDialogClose"
    >
      <el-form :model="adjustFrom" ref="adjustFromRef" label-width="100px" :rules="adjustFromRules">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="记录月份" prop="recordMonth">
              <el-date-picker v-model="adjustFrom.recordMonth" type="date" disabled></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报销原因" prop="name">
              <el-input v-model="adjustFrom.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="报销开始时间" prop="startTime" width="150px">
              <el-date-picker
                v-model="adjustFrom.startTime"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报销结束时间" prop="endTime" width="150px">
              <el-date-picker
                v-model="adjustFrom.endTime"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="报销金额" prop="money">
              <el-input-number v-model="adjustFrom.money" width="20px" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际金额" prop="actualMoney" width="70px">
              <el-input-number v-model="adjustFrom.actualMoney" width="20px" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="报销备注" prop="applyRemark" width="150px">
              <el-input v-model="adjustFrom.applyRemark" width="20px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-table
        :data="attendanceList"
        border
        stripe
        style="width: 100%"
        show-summary
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column label="名称" prop="name" />
        <el-table-column label="绑定事件" prop="refAttendanceTravelId" width="220">
          <template slot-scope="scope">
            <template>
              <el-select
                multiple
                v-model="scope.row.refAttendanceTravelId"
                placeholder="请选择"
                disabled
              >
                <el-option
                  v-for="item in refAttendanceTravelList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value+''"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="类别" prop="category" width="140">
          <template slot-scope="scope">
            <template>
              <el-select
                v-model="scope.row.category"
                placeholder="请选择"
                :disabled="!scope.row.isEdit"
              >
                <el-option
                  v-for="item in receiptTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- <el-checkbox v-model="scope.row.attendanceType">是</el-checkbox> -->
            </template>
            <!-- <span v-else>{{scope.row.attendanceType}}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="发票类型" prop="type" width="100">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择" :disabled="!scope.row.isEdit">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="发票号" prop="number" />
        <el-table-column label="发票日期" prop="date" width="100" />
        <el-table-column label="发票金额" prop="money" />
        <el-table-column label="实际金额" prop="actualMoney" />
        <el-table-column label="报销金额" prop="reimbureseMoney" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adjustDialogClose">取 消</el-button>
        <el-button type="primary" @click="adjustAendance">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { listApi, getEventDataApi } from "@/api/work/attendance/receipt";
import { saveOrUpdateApi } from "@/api/work/attendance/reimburse";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      typeList: [
        {
          value: 1,
          name: "普票"
        }
      ],
      adjustDialogVisible: false,
      adjustFromRules: {},
      refAttendanceTravelList: [],
      ids: null,
      attendanceList: [],
      receiptTypeList: [],
      multipleSelection: [],
      options: [],
      adjustFrom: {
        id: null,
        recordMonth: Date.now(),
        applyBeginData: "",
        applyEndData: "",
        applyMoney: "",
        applyRemark: "",
        applyStatus: "",
        isExtraGrant: null
      }
    };
  },
  methods: {
    show(id, type) {
      this.adjustDialogVisible = true;
      this.ids = id;
      this.getReceiptTypeList();
      this.getEventDataButton();
      this.listApiButton();
    },
    adjustDialogClose() {
      (this.multipleSelection = []), (this.attendanceList = []);
      this.$refs.adjustFromRef.resetFields();
      this.adjustDialogVisible = false;
      this.$parent.getAttendanceList();
    },
    adjustAendance() {
      this.adjustFrom.refAttendanceId = this.ids;
      this.adjustFrom.userDataList = this.multipleSelection;
      saveOrUpdateApi(this.adjustFrom).then(res => {
        this.adjustDialogClose();
      });
    },
    listApiButton() {
      listApi({ status: 1, refAttendanceId: this.ids }).then(res => {
        this.attendanceList = res.result;
      });
    },
    getReceiptTypeList() {
      getWorkEnumList({ enumName: "ReceiptTypeEnum" }).then(res => {
        this.receiptTypeList = res.result;
      });
    },
    getEventDataButton() {
      getEventDataApi({ refAttendanceId: this.ids }).then(res => {
        this.refAttendanceTravelList = res.result;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>