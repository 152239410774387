import { getAction, getByIdAction, postAction, putAction, getByTypeAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunTakeRecord'
//调用 import { addAttend } from "@/api/work/attendance/takeRecord";

//列表
const list = (params) => getAction(baseURL + '/list', params);

//新增
const addTaskRecodeOne = (params) => postAction(baseURL + '/addOne', params);

//保存
const editTaskRecodeOne = (params) => putAction(baseURL + '/edit', params);

//编辑
const editTaskRecodeAll = (params) => postAction(baseURL + '/addTakeAll', params);

//编辑
const deleteByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

export {
    list,
    addTaskRecodeOne,
    editTaskRecodeOne,
    editTaskRecodeAll,
    deleteByIdApi
}
