<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>工作管理</el-breadcrumb-item>
      <el-breadcrumb-item>考勤记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <!-- 按钮区 -->
      <div>{{this.newDatas}}</div>

      <table class="AllTable">
        <tr>
          <td>序号</td>
          <td>累积加班天数</td>
          <td>累积已调休天数</td>
          <td>剩余调休天数</td>
          <td>当月是否更新</td>
          <td>流程中的金额</td>
        </tr>
        <tr :data="attendanceAll">
          <td></td>
          <td>{{attendanceAll.accumulateOvertimeDay}}</td>
          <td>{{attendanceAll.accumulateRestDay}}</td>
          <td>{{attendanceAll.accumulateOvertimeDay+attendanceAll.accumulateRestDay}}</td>
          <td>{{attendanceAll.lastIsUpdate}}</td>
          <td>{{attendanceAll.applyMoney}}</td>
        </tr>
      </table>

      <div>
        <el-form layout="inline">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8">
              <el-form-item label="报销状态">
                <el-select
                  v-model="queryParams.applyStatus"
                  placeholder="请选择"
                  @change="getApplyStatus"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="8">
              <el-form-item label="记录月份">
                <el-date-picker
                  v-model="queryParams.recordMonth"
                  type="month"
                  placeholder="选择月"
                  :clearable="false"
                  @clear="handleQuery()"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="8">
              <el-button type="primary" @click="getAttendanceList()">搜索</el-button>
              <el-button type="primary" @click="getAttendanceAll()">重置</el-button>
              <!-- @click="primaryRest()" -->
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin:9px 2px">
        <el-button type="success" icon="el-icon-add" size="min" @click="addAttend()">新增</el-button>
        <el-button type="success" icon="el-icon-edit" size="min" @click="editAtten()">修改</el-button>
        <el-button type="success" icon="el-icon-edit" size="min" @click="adjustDialog()">报销</el-button>
        <!-- <el-button type="success" icon="el-icon-add" size="min" @click="delectAttend()">删除</el-button> -->
        <!-- <el-button type="danger" icon="el-icon-edit" size="min" @click="selectAttachment(id)">查看所有附件</el-button> -->
      </el-row>
      <!-- 列表区域 -->
      <el-table :data="attendanceList" border stripe style="width: 100%" show-summary>
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="记录月份" prop="recordMonth" />
        <el-table-column label="加班小时" prop="workOvertime" />
        <el-table-column label="调休小时" prop="restDays" />
        <!-- <el-table-column label="调休天数" prop="restDay" :formatter="restDayTimeChange" /> -->
        <el-table-column label="实际上班天数" prop="workDay" />
        <el-table-column label="加班补贴" prop="extraMoney" />
        <el-table-column label="请假天数" prop="leaveDay" :formatter="leaveDayTimeChange" />
        <el-table-column label="出差明细" prop="isTrip">
          <template slot-scope="scope">
            <el-button
              style="color:#108ee9;cursor:pointer;"
              @click="showTripButton(scope.row)"
            >{{scope.row.tripDay}}天</el-button>
          </template>
        </el-table-column>
        <el-table-column label="加班明细" prop="workOvertime">
          <template slot-scope="scope">
            <el-button
              style="color:#108ee9;cursor:pointer;"
              @click="travelFlowerButtonTwo(scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="发票明细" prop="workOvertime">
          <template slot-scope="scope">
            <el-button
              style="color:#108ee9;cursor:pointer;"
              @click="attendanceReceiptButton(scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="报销明细" prop="workOvertime">
          <template slot-scope="scope">
            <el-button style="color:#108ee9;cursor:pointer;" @click="reimburseButton(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="考勤备注" prop="attendanceRemark" />
        <el-table-column label="加班餐补是否发放" prop="isExtraGrant" />
      </el-table>
      <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    </el-card>

    <el-dialog
      title="调整状态"
      :visible.sync="adjustDialogVisible"
      width="70%"
      @close="adjustDialogClose"
    >
      <el-form :model="adjustFrom" ref="adjustFromRef" label-width="100px" :rules="adjustFromRules">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="记录月份" prop="recordMonth">
              <el-date-picker
                v-model="adjustFrom.recordMonth"
                type="month"
                placeholder="选择月"
                disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-checkbox
              v-model="adjustFrom.isExtraGrant"
              label="加班报销是否发放"
              border
              size="medium"
              style="margin:10px 30px  40px 30px"
            ></el-checkbox>
          </el-col>
        </el-row>

        <div>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="报销金额" prop="applyMoney">
                <el-input-number v-model="adjustFrom.applyMoney" width="20px" />
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="报销状态" prop="applyStatus" width="70px">
                <el-select
                  v-model="adjustFrom.applyStatus"
                  placeholder="请选择"
                  @change="getApplyStatus"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="报销开始时间" prop="recordMonth" width="150px">
                <el-date-picker
                  v-model="adjustFrom.applyBeginData"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="报销结束时间" prop="workDay" width="150px">
                <el-date-picker
                  v-model="adjustFrom.applyEndData"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="报销备注" prop="applyRemark" width="150px">
                <el-input v-model="adjustFrom.applyRemark" width="20px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="adjustDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adjustAendance">确 定</el-button>
      </span>
    </el-dialog>

    <attendance-Add ref="addModalForm"></attendance-Add>
    <attendanceTrip ref="attendanceTripFrom"></attendanceTrip>
    <travelFlower ref="travelFlowerFrom"></travelFlower>
    <attendanceReceipt ref="attendanceReceiptFrom"></attendanceReceipt>
    <adjustReimbures ref="adjustReimburesFrom" />
    <reimburse ref="reimburseFrom" />
  </div>
</template>
<script>
import reimburse from "./modules/reimburse";
import attendanceAdd from "./modules/attendanceAdd";
import attendanceTrip from "./modules/attendanceTrip";
import travelFlower from "./modules/travelFlower";
import attendanceReceipt from "./modules/attendanceReceipt";
import format from "@/plugins/format.js";
import adjustReimbures from "./modules/adjustReimburse";
import {
  delectAttendById,
  listApi,
  getTotalVacationApi,
} from "@/api/work/attendance/attendance";
export default {
  name: "attendance",
  components: {
    attendanceAdd,
    attendanceTrip,
    travelFlower,
    attendanceReceipt,
    adjustReimbures,
    reimburse
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "草稿"
        },
        {
          value: "2",
          label: "流程中"
        },
        {
          value: "3",
          label: "完成"
        }
      ],
      activeName: "first",
      radio: "",
      //查询条件
      queryParams: {
        recordMonth: "",
        sysProfitCenterId: "",
        customerName: "",
        sendState: "",
        applyStatus: "",
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      key: 1,
      differ: false,
      currentRow: null,
      queryInfo: {
        query: "",
        //当前页数
        pagenum: 1,
        pagesize: 2
      },
      queryAll: {},
      attendanceList: [],
      attendanceAll: [],
      total: 1,
      newDatas: "",
      addDialogVisible: false,
      adjustDialogVisible: false,
      id: null,
      addFrom: {
        recordMonth: new Date(),
        workDay: null,
        overtimeDay: "",
        restDay: "",
        applyMoney: "",
        applyStatus: "",
        applyBeginData: "",
        applyEndData: "",
        attendanceRemark: "",
        userCompany: "工博瑞云"
      },
      addFromRules: {
        workDay: [
          { required: true, message: "请输入上班天数", trigger: "blur" }
        ],
        overtimeDay: [
          { required: true, message: "请输入加班天数", trigger: "blur" }
        ],
        restDay: [
          { required: true, message: "请输入调休天数", trigger: "blur" }
        ]
      },
      adjustFromRules: {},
      adjustFrom: {
        id: null,
        recordMonth: null,
        applyBeginData: "",
        applyEndData: "",
        applyMoney: "",
        applyRemark: "",
        applyStatus: "",
        isExtraGrant: null
      },
      uniqueId: null
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.getAttendanceList();
    },
    "queryParams.pageSize"(val) {
      this.getAttendanceList();
    },
  },
  created() {
    this.addFrom.applyStatus = this.options[0].value;
    this.getAttendanceList();
    this.getAttendanceAll();
    this.handleGetDate();
  },
  methods: {
    //这里是获取列表数据
    async getAttendanceList() {
      this.queryParams.recordMonth = format(
        this.queryParams.recordMonth,
        "YYYY-MM"
      );

      listApi(this.queryParams).then(res => {
        if (res.code !== '200') return this.$message.error("获取考勤信息失败");
        this.attendanceList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    //这里是获取总考勤表
    async getAttendanceAll() {
      getTotalVacationApi().then(res => {
        if (res.code !== '200') return this.$message.error("获取考勤信息失败");
        console.log(res);
      });

      // this.attendanceAll = res.result;
    },
    //这里在监控页面
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getAttendanceList();
    },
    getApplyStatus() {
      if (this.addFrom.applyStatus === 2) {
        //这里加入对报销时间开始为必填的需求
      }
    },
    changeOther() {
      this.addFrom.applyMoney =
        this.addFrom.workDay * 50 + this.addFrom.otherMoney;
    },
    //这里是获取到当前的时间
    handleGetDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.newDatas = currentdate;
    },
    //清空数据
    addDigloClose() {
      this.$refs.addFromRef.resetFields();
    },
    adjustDialogClose() {
      this.$refs.adjustFromRef.resetFields();
    },
    //调整状态
    adjustAendance() {
      this.$refs.adjustFromRef.validate(async valid => {
        if (!valid) return;
        if (this.adjustFrom.applyStatus == "草稿") {
          this.adjustFrom.applyStatus = 1;
        }
        if (this.adjustFrom.applyStatus == "流程中") {
          this.adjustFrom.applyStatus = 2;
        }
        if (this.adjustFrom.applyStatus == "完成") {
          this.adjustFrom.applyStatus = 3;
        }
        if (this.adjustFrom.applyStatus == 3) {
          console.log(this.adjustFrom.applyEndData);
          if (this.adjustFrom.applyEndData == null) {
            return this.$message.error("结束时间不能为空");
          }
        }

        this.adjustFrom.isExtra = this.adjustFrom.isExtra == true ? 1 : 2;
        this.adjustFrom.isExtraGrant =
          this.adjustFrom.isExtraGrant == true ? 1 : 2;

        const { data: res } = this.$http
          .put("xun/work/xunAttendance/adjust", this.adjustFrom)
          .then(res => {
            if (res.success) {
              return this.$message.error(res.data.message);
            } else {
              this.adjustDialogVisible = false;
              this.getAttendanceList();
              return this.$message.success(res.data.message);
            }
          });
      });
    },

    wordMoney() {
      if (this.addFrom.workDay != null) {
        if (this.addFrom.otherMoney == undefined) {
          this.addFrom.otherMoney = 0;
        }
        this.addFrom.applyMoney =
          this.addFrom.workDay * 50 + this.addFrom.otherMoney;
      }
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.uniqueId = row.uniqueId;
      this.templateSelection = row;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChangeqq(val) {
      this.currentRow = val;
    },
    //这里是点击调整
    adjustDialog(row) {
      if (this.uniqueId == null) return this.$message.error("请选择有效的一列");
      this.$refs.adjustReimburesFrom.show(this.uniqueId, 2);
    },
    hasBackFormat(row, column) {
      if (row.applyStatus == 1) {
        return "草稿";
      }
      if (row.applyStatus == 2) {
        return "流程中";
      }
      if (row.applyStatus == 3) {
        return "完成";
      }
    },

    selectAttachment(id) {
      return this.$message.error("目前还没开放");
    },
    primaryRest() {
      console.log("进来了");
      this.queryParams.recordMonth = "";
      this.queryParams.applyStatus = "";
      this.getAttendanceList();
    },
    addAttend() {
      this.$refs.addModalForm.show(null, 1);
      this.$refs.addModalForm.title = "新增";
    },
    editAtten() {
      if (this.uniqueId == null) return this.$message.error("请选择有效的一列");
      this.$refs.addModalForm.show(this.uniqueId, 2);
      this.$refs.addModalForm.title = "编辑";
    },
    showTripButton(row) {
      this.$refs.attendanceTripFrom.show(row);
    },
    showTripButtonTwo() {
      if (this.id == null) return this.$message.error("请选择有效的一列");
      this.$refs.attendanceTripFrom.show(this.id);
    },
    delectAttend() {
      if (this.id == null) return this.$message.error("请选择有效的一列");
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delectAttendById(this.id).then(res => {
            this.getAttendanceList();
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    isApplyAdjustButton() {},
    travelFlowerButton() {
      if (this.id == null) return this.$message.error("请选择有效的一列");
      this.$refs.travelFlowerFrom.show(this.id);
    },
    travelFlowerButtonTwo(row) {
      this.$refs.travelFlowerFrom.show(row);
    },
    reimburseButton(row) {
      this.$refs.reimburseFrom.show(row);
    },
    attendanceReceiptButton(row) {
      this.$refs.attendanceReceiptFrom.show(row);
    },

    overTimeChange(row, column) {
      return this.changSecondToDate(row.overAllTime);
    },
    restDayTimeChange(row, column) {
      return this.changSecondToDate(row.restDayTime);
    },
    workDayTimeChange(row, column) {
      return this.changSecondToDate(row.workDayTime);
    },
    leaveDayTimeChange(row, column) {
      return this.changSecondToDate(row.leaveDayTime);
    }
  }
};
</script>
<style lang="less" scoped>
table {
  margin-left: auto;
  border: 5px solid red;
  font-size: 15px;
  text-align: center;
  line-height: 24px;
}
</style>