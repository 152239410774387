<template>
  <div>
    <el-dialog title="发票明细" :visible.sync="dialogVisible" width="87%" :before-close="handleClose">
      <!-- 列表区域 -->
      <el-button @click="addFlower">新增</el-button>
      <el-button @click="handleClose">关闭</el-button>
      <el-table :data="attendanceList" border stripe style="width: 100%" show-summary>
        <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editRowMember(scope.$index,attendanceList, scope.row)"
              v-if="!scope.row.isEdit"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="addRowMember(scope.$index, scope.row)"
              v-else
            >保存</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.prevent="deleteRow(scope.$index, scope.row,attendanceList)"
            >移除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" width="180">
          <template slot-scope="scope">
            <el-input
              class="edit-input"
              v-model="scope.row.name"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            />
          </template>
        </el-table-column>
        <el-table-column label="绑定事件" prop="refAttendanceTravelId" width="220">
          <template slot-scope="scope">
            <template>
              <el-select
              multiple
                v-model="scope.row.refAttendanceTravelId"
                placeholder="请选择"
                :disabled="!scope.row.isEdit"
              >
                <el-option
                  v-for="item in refAttendanceTravelList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value+''"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="类别" prop="category" width="140">
          <template slot-scope="scope">
            <template>
              <el-select
                v-model="scope.row.category"
                placeholder="请选择"
                :disabled="!scope.row.isEdit"
              >
                <el-option
                  v-for="item in receiptTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- <el-checkbox v-model="scope.row.attendanceType">是</el-checkbox> -->
            </template>
            <!-- <span v-else>{{scope.row.attendanceType}}</span> -->
          </template>
        </el-table-column>


        <el-table-column label="发票类型" prop="type" width="100">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择" :disabled="!scope.row.isEdit">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="发票号" prop="number" width="180">
          <template slot-scope="scope">
            <template>
              <el-input-number
                class="edit-input"
                v-model="scope.row.number"
                :disabled="!scope.row.isEdit"
                placeholder="请输入内容"
              ></el-input-number>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="发票日期" prop="date" width="180">
          <template slot-scope="scope">
            <el-date-picker
              style="width: 100%"
              v-model="scope.row.date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="!scope.row.isEdit"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="发票金额" prop="money" width="180">
          <template slot-scope="scope">
            <el-input-number
              class="edit-input"
              v-model="scope.row.money"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="实际金额" prop="actualMoney" width="180">
          <template slot-scope="scope">
            <el-input-number
              class="edit-input"
              v-model="scope.row.actualMoney"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="报销金额" prop="reimbureseMoney" width="180">
          <template slot-scope="scope">
            <template>
              <el-input-number
                class="edit-input"
                v-model="scope.row.reimbureseMoney"
                :disabled="!scope.row.isEdit"
                placeholder="请输入内容"
              ></el-input-number>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="补税金额" prop="taxMoney" width="180">
          <template slot-scope="scope">
            <el-input-number
              class="edit-input"
              v-model="scope.row.taxMoney"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="180">
          <template slot-scope="scope">
            <el-input
              class="edit-input"
              v-model="scope.row.remark"
              :disabled="!scope.row.isEdit"
              placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="180" />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  pagelistApi,
  addApi,
  deleteByIdApi,
  listApi,
  getEventDataApi
} from "@/api/work/attendance/receipt";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      typeList: [
        {
          value: 1,
          name: "普票"
        }
      ],
      options: [],
      refAttendanceTravelList: [],
      attendanceList: [],
      title: null,
      dialogVisible: false,
      receiptTypeList: [],
      queryInfo: {
        query: ""
      },
      ids: null,
      editShowAll: true,
      attendType: 1
    };
  },
  watch: {
    isEdit(newValue, oldValue) {
      console.log(newValue, oldValue);
    }
  },
  methods: {
    show(row) {
      this.dialogVisible = true;
      this.ids = row.uniqueId;
      this.getEventDataButton();
      this.getReceiptTypeList();
        this.listButton();
    },
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
      this.$parent.getAttendanceList();
    },
    listButton() {
      pagelistApi({ refAttendanceId: this.ids }).then(res => {
        this.attendanceList = res.result.records;
      });
    },
    editRowMember(index, row, rowOne) {
      row.isEdit = Boolean(true);
      row[index].isEdit = Boolean(true);
      row.refAttendanceId = this.ids;
      this.editShowAll = true;
    },
    addRowMember(index, row) {
      row.isEdit = Boolean(false);
      row.refAttendanceId = this.ids;
      addApi(row).then(res => {
        this.listButton();
      });
      this.editShowAll = false;
    },
    addFlower() {
      var newAttFlow = {
        refAttendanceId: this.ids,
        isEdit: true,
        status:1,
      };
      this.attendanceList.push(newAttFlow);
    },
    deleteRow(index, row, dataList) {
      if (row.id == null) {
        dataList.splice(index, 1);
      } else {
        deleteByIdApi(row.id).then(res => {
          this.listButton();
        });
      }
    },
    attendanceTypeButton(type) {
      this.attendType = type;
    },

    getEventDataButton() {
      getEventDataApi({ refAttendanceId: this.ids }).then(res => {
        this.refAttendanceTravelList = res.result;
      });
    },
    getReceiptTypeList() {
      getWorkEnumList({ enumName: "ReceiptTypeEnum" }).then(res => {
        this.receiptTypeList = res.result;
      });
    }
  }
};
</script>
<style scoped>
</style>