import { getAction, getByIdAction, postAction, putAction, getByTypeAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/travel'
//调用 import { addAttend } from "@/api/work/attendance/travel";

//新增
const list = (params) => getAction(baseURL + '/list', params);

const editTravel = (params) => putAction(baseURL + '/edit', params);

const deleteByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);


export {
    list,
    editTravel,
    deleteByIdApi
}