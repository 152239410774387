<template>
  <div>
    <el-dialog title="考勤明细" :visible.sync="dialogVisible" width="87%" :before-close="handleClose">
      <!-- 列表区域 -->
      <el-button @click="addFlower">新增</el-button>
      <el-button @click="handleClose">关闭</el-button>
      <!-- <el-button @click="addFlowerAll">保存</el-button> -->
      <el-table :data="attendanceList" border stripe style="width: 100%" show-summary>
        <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editRowMember(scope.$index,attendanceList, scope.row)"
              v-if="!scope.row.isEdit"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="addRowMember(scope.$index, scope.row)"
              v-else
            >保存</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.prevent="deleteRow(scope.$index, scope.row,attendanceList)"
            >移除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="申请类型" prop="attendanceType" width="100">
          <template slot-scope="scope">
            <template>
              <el-select
                v-model="scope.row.attendanceType"
                placeholder="请选择"
                :disabled="!scope.row.isEdit"
                @change="attendanceTypeButton(scope.row.attendanceType)"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="是否申请" prop="isApplyDate" width="80">
          <template slot-scope="scope">
            <template>
              <el-checkbox
                v-model="scope.row.isApplyDate"
                :disabled="!scope.row.isEdit"
                :checked="scope.row.isApplyDate===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </template>
            <!-- <span v-else>{{scope.row.isApplyDate}}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="是否过夜" prop="isOvernight" width="80">
          <template slot-scope="scope">
            <template>
              <el-checkbox
                v-model="scope.row.isOvernight"
                :disabled="!scope.row.isEdit"
                :checked="scope.row.isApplyDate===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="加班时间" prop="overtime" width="200" v-if="this.attendType==1">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-date-picker
                style="width: 170px;"
                v-model="scope.row.overtime"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </template>
            <span v-else>{{scope.row.overtime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="调休时间" prop="overtime" width="200" v-if="this.attendType==2">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-date-picker
                style="width: 170px;"
                v-model="scope.row.overtime"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </template>
            <span v-else>{{scope.row.overtime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="overtimeEndAll" width="230">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <!-- start: scope.row.overtimeEnd, -->
              <el-time-select
                value-format="HH:mm"
                placeholder="起始时间"
                style="width: 100px;"
                v-model="scope.row.overtimeEnd"
                :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00'
              }"
              ></el-time-select>
              <el-time-select
                @change="overButton(scope.row)"
                value-format="HH:mm"
                style="width: 100px;"
                placeholder="结束时间"
                v-model="scope.row.overtimeBegin"
                :picker-options="{
                start: '00:00',
                step: '00:10',
                end: '24:00',
              
              }"
              ></el-time-select>
              <!--   minTime: scope.row.overtimeEnd -->
            </template>
            <span v-else>{{scope.row.timeText}}</span>
          </template>
        </el-table-column>
        <el-table-column label="时长" prop="overtimeHour" width="220">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                class="edit-input"
                v-model="scope.row.overtimeHour"
                placeholder="输入时长"
              />
            </template>
            <span v-else>{{scope.row.overtimeHour}}</span>
          </template>
        </el-table-column>

        <el-table-column label="是否可以报销" prop="isApply" width="80">
          <template slot-scope="scope">
            <template>
              <el-checkbox
                v-model="scope.row.isApply"
                :disabled="!scope.row.isEdit"
                :checked="scope.row.isApply===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="是否发放餐补" prop="isExtraGrant" width="80">
          <template slot-scope="scope">
            <template>
              <el-checkbox
                v-model="scope.row.isExtraGrant"
                :disabled="!scope.row.isEdit"
                :checked="scope.row.isExtraGrant===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </template>
            <!-- <span v-else>{{scope.row.isExtraGrant}}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="出发地" prop="departure" width="120">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input class="edit-input" v-model="scope.row.departure" placeholder="请输入内容" />
            </template>
            <span v-else>{{scope.row.departure}}</span>
          </template>
        </el-table-column>
        <el-table-column label="目的地" prop="destination" width="120">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input class="edit-input" v-model="scope.row.destination" placeholder="请输入内容" />
            </template>
            <span v-else>{{scope.row.destination}}</span>
          </template>
        </el-table-column>
        <el-table-column label="打车报销" prop="taskMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number class="edit-input" v-model="scope.row.taskMoney" placeholder="请输入内容"></el-input-number>
            </template>
            <span v-else>{{scope.row.taskMoney}}</span>
          </template>
        </el-table-column>

        <el-table-column label="备注" prop="departure">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input class="edit-input" v-model="scope.row.remark" placeholder="请输入内容" />
            </template>
            <span v-else>{{scope.row.remark}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  list,
  addTaskRecodeOne,
  editTaskRecodeOne,
  editTaskRecodeAll,
  deleteByIdApi
} from "@/api/work/attendance/takeRecord";
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "加班"
        },
        {
          value: 2,
          label: "调休"
        }
      ],
      attendanceList: [],
      title: null,
      dialogVisible: false,
      queryInfo: {
        query: ""
      },
      ids: null,
      editShowAll: true,
      attendType: 1
    };
  },
  methods: {
    show(row) {
      this.dialogVisible = true;
      this.ids = row.uniqueId;
      this.listButton();
    },
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
      this.$parent.getAttendanceList();
    },
    listButton() {
      this.queryInfo.refAttendanceId = this.ids;
      list(this.queryInfo).then(res => {
        // if (res.result.records.length != 0) {
        //   this.attendanceList = res.result.records;
        // }
        this.attendanceList = res.result.records;
      });
    },
    editRowMember(index, row, rowOne) {
      // rowOne.isOvernight = rowOne.isOvernight == 1 ? true : false;
      // rowOne.isApply = rowOne.isApply == 1 ? true : false;
      // rowOne.isExtraGrant = rowOne.isExtraGrant == 1 ? true : false;
      // rowOne.isApplyDate = rowOne.isApplyDate == 1 ? true : false;
      row.isEdit = Boolean(true);
      row[index].isEdit = Boolean(true);
      row.refAttendanceId = this.ids;
      this.editShowAll = true;
    },
    addRowMember(index, row) {
      row.isEdit = Boolean(false);
      row.refAttendanceId = this.ids;
      // row.isExtraGrant = row.isExtraGrant == true ? 1 : 2;
      // row.isOvernight = row.isOvernight == true ? 1 : 2;
      // row.isApplyDate = row.isApplyDate == true ? 1 : 2;
      // row.isApply = row.isApply == true ? 1 : 2;
      addTaskRecodeOne(row).then(res => {
        this.listButton();
      });
      this.editShowAll = false;
    },
    addFlower() {
      var newAttFlow = {
        isEdit: true,
        attendanceType: 1
      };
      this.attendanceList.push(newAttFlow);
    },
    deleteRow(index, row, dataList) {
      if (row.id == null) {
        dataList.splice(index, 1);
      } else {
        deleteByIdApi(row.id).then(res => {
          this.listButton();
        });
      }
    },
    addFlowerAll() {
      editTaskRecodeAll({ xunTakeRecordRespVOS: this.attendanceList }).then(
        res => {
          console.log(res);
        }
      );
    },

    overButton(val) {
      var endHours = val.overtimeEnd.split(":")[0];
      var beginHours = val.overtimeBegin.split(":")[0];
      var endMinutes = val.overtimeEnd.split(":")[1];
      var beginMinutes = val.overtimeBegin.split(":")[1];
      //全部换成分钟去计算
      if (val.isOvernight == 1) {
        //过夜计算  先全部换做数值去计算  需要将 结束时间加24小时
        beginHours = Number(beginHours) + Number(24);
      }
      //没有过夜
      endHours = Number(endHours * 60);
      var endTime = Number(endHours) + Number(endMinutes);
      beginHours = Number(beginHours * 60);
      var beginTime = Number(beginHours) + Number(beginMinutes);
      console.log(endTime);
      console.log(beginTime);
      var dur = Number(beginTime - endTime);
      dur = Number(dur / 60).toFixed(2);
      val.overtimeHour = dur;
    },
    attendanceTypeButton(type) {
      console.log("申请类型s", type);
      this.attendType = type;
      // if(type==1){
      //     //加班
      //     this.attendType=type
      // }
      // if(type==2){
      //     //调休
      //     this.attendType=type
      // }
    }
  }
};
</script>
<style scoped>
</style>