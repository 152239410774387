<template>
  <div>
    <el-dialog title="出差明细" :visible.sync="dialogVisible" width="82%" :before-close="handleClose">
      <!-- 列表区域 -->
      <el-button @click="addAttendance">新增</el-button>
      <!-- <el-button @click="handleClose">保存</el-button> -->
      <span>一共出差：{{this.tripDay}}天</span>
      <el-table :data="attendanceList" border stripe style="width: 100%" show-summary max-height="700px">
        <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editRowMember(scope.$index, attendanceList)"
              v-if="!scope.row.isEdit"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="addRowMember(scope.$index, scope.row)"
              v-else
            >保存</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.prevent="deleteRow(scope.$index,scope.row, attendanceList)"
            >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="当天时间" prop="sameDay" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-date-picker
                style="width: 100%"
                v-model="scope.row.sameDay"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </template>
            <span v-else>{{scope.row.sameDay}}</span>
          </template>
        </el-table-column>
        <el-table-column label="事情名称" prop="name" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.name"
                placeholder="请输入内容"
              ></el-input>
            </template>
            <span v-else>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="当天可报销总金额" prop="sameUserMoney" fixed="right" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.sameUserMoney"
                placeholder="请输入内容"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.sameUserMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="当天总消费" prop="sameSumMoney" fixed="right" width="100" />

        <el-table-column label="出发地" prop="departure" width="120">
          <template slot-scope="scope">
            <template>
              <el-input
                class="edit-input"
                v-model="scope.row.departure"
                :disabled="!scope.row.isEdit"
                placeholder="请输入内容"
              />
            </template>
          </template>
        </el-table-column>

        <el-table-column label="目的地" prop="destination" width="120">
          <template slot-scope="scope">
            <template>
              <el-input
                class="edit-input"
                :disabled="!scope.row.isEdit"
                v-model="scope.row.destination"
                placeholder="请输入内容"
              />
            </template>
          </template>
        </el-table-column>
        <el-table-column label="出差方式" prop="travelType" width="160">
          <template slot-scope="scope">
            <template>
              <el-select
                clearable
                v-model="scope.row.travelType"
                :disabled="!scope.row.isEdit"
                prop="travelType"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
            <!-- <span v-else v-if="scope.row.isEdit">{{scope.row.travelTypeName}}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="座位" prop="travelSeat" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.travelSeat"
                placeholder="请输入内容"
              ></el-input>
            </template>
            <span v-else>{{scope.row.travelSeat}}</span>
          </template>
        </el-table-column>
        <el-table-column label="方式价格" prop="travelMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.travelMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.travelMoney}}</span>
          </template>
        </el-table-column>

        <el-table-column label="早餐" prop="breakfastMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.breakfastMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.breakfastMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="中餐" prop="lunchMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.lunchMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.lunchMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="午餐" prop="dinnerMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.dinnerMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.dinnerMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="夜宵" prop="supperMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.supperMoney"
                @change="countAllSumMony(scope.row)"
                placeholder="请输入内容"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.supperMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="住宿费" prop="stayMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.stayMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.stayMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="酒店名称" prop="hotelName" width="120">
          <template slot-scope="scope">
            <template>
              <el-input
                class="edit-input"
                v-model="scope.row.hotelName"
                :disabled="!scope.row.isEdit"
                placeholder="请输入内容"
              />
            </template>
          </template>
        </el-table-column>
        <el-table-column label="酒店地址" prop="hotelAddress" width="120">
          <template slot-scope="scope">
            <template>
              <el-input
                class="edit-input"
                v-model="scope.row.hotelAddress"
                :disabled="!scope.row.isEdit"
                placeholder="请输入内容"
              />
            </template>
          </template>
        </el-table-column>

        <el-table-column label="其他费用" prop="otherMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.otherMoney"
                @change="countAllSumMony(scope.row)"
                placeholder="请输入内容"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.otherMoney}}</span>
          </template>
        </el-table-column>

        <el-table-column label="可报销打车费" prop="noTaskMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.noTaskMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.noTaskMoney}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="可报销打车费备注" prop="noTaskRemark" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input class="edit-input" v-model="scope.row.noTaskRemark" placeholder="请输入内容"></el-input>
            </template>
            <span v-else>{{scope.row.noTaskRemark}}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="其他不可报销消费" prop="myMoney" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input-number
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.myMoney"
                placeholder="请输入内容"
                @change="countAllSumMony(scope.row)"
              ></el-input-number>
            </template>
            <span v-else>{{scope.row.myMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.isEdit">
              <el-input
                style="width: 150px"
                class="edit-input"
                v-model="scope.row.remark"
                placeholder="请输入内容"
              ></el-input>
            </template>
            <span v-else>{{scope.row.remark}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { list, editTravel, deleteByIdApi } from "@/api/work/attendance/travel";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  data() {
    return {
      attendanceList: [],
      title: null,
      tripDay: 0,
      dialogVisible: false,
      queryInfo: {},
      options: [],
      ids: null,
      receiptEdit: true
    };
  },
  watch: {
    tripDay(old, newData) {}
  },
  methods: {
    show(row) {
      this.dialogVisible = true;
      this.ids = row.uniqueId;
      this.listButton();
      this.getWorkEnumListButton();
      this.tripDay = row.tripDay;
    },
    handleClose() {
      this.dialogVisible = false;
      this.addFrom = {};
      this.$parent.getAttendanceList();
    },
    listButton() {
      this.queryInfo.id = this.ids;
      this.queryInfo.pageSize=9999;
      list(this.queryInfo).then(res => {
        this.attendanceList = res.result.records;
        this.tripDay = this.attendanceList.length;
      });
    },
    editRowMember(index, row) {
      row.isEdit = Boolean(true);
      row[index].isEdit = Boolean(true);
    },
    addRowMember(index, row) {
      row.isEdit = Boolean(false);
      editTravel(row).then(res => {
        console.log(res);
      });
    },
    addAttendance() {
      var dataAttend = {
        refAttendanceId: this.ids,
        id: null,
        isEdit: true,
        breakfastMoney: 0,
        dinnerMoney: 0,
        factTravelMoney: 0,
        lunchMoney: 0,
        myMoney: 0,
        noTaskMoney: 0,
        noTaskRemark: "",
        otherMoney: 0,
        receiptData: null,
        receiptMoney: 0,
        receiptNumber: 0,
        receiptRemark: null,
        receiptType: 1,
        remark: null,
        sameDay: null,
        sameSumMoney: 0,
        sameUserMoney: 0,
        stayMoney: 0,
        supperMoney: 0,
        travelMoney: 0,
        travelType: "xun.personal.rail",
        tripType: null,
        userTaskMoney: 0,
        userTaskRemark: null
      };
      this.attendanceList.push(dataAttend);
    },
    deleteRow(index, row, dataList) {
      if (row.id == null) {
        dataList.splice(index, 1);
      } else {
        deleteByIdApi(row.id).then(res => {
          this.listButton();
        });
      }
    },
    getWorkEnumListButton() {
      getWorkEnumList({ enumName: "TravelTypeEnum" }).then(res => {
        this.options = res.result;
      });
    },
    countAllSumMony(row) {
      console.log("res", row);
      row.sameSumMoney =
        parseInt(row.travelMoney) +
        parseInt(row.breakfastMoney) +
        parseInt(row.lunchMoney) +
        parseInt(row.dinnerMoney) +
        parseInt(row.supperMoney) +
        parseInt(row.stayMoney) +
        parseInt(row.otherMoney) +
        parseInt(row.noTaskMoney) +
        parseInt(row.myMoney);
    }
  }
};
</script>
<style scoped>
</style>